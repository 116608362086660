<template>
  <div class="dp-top-menu">
    <button
      type="button"
      class="dp-top-menu-button"
      v-ripple="$utilities.getRippleOptions('dark')"
      @click="onClickDripMenu">
      <span class="dp-top-menu-button__label">
        <Icon name="dpMenu" :width="38" :height="34" />
      </span>
    </button>

    <transition name="fade">
      <div class="dp-top-menu-content" v-if="isActiveMenu">
        <div @click="deactivateMenu" class="dp-top-menu-content-overlay" />

        <div class="dp-top-menu-content-body">
          <h1 class="dp-top-menu-content-body__title">
            {{ WORDS.DRIP_POD_MENU.TITLE }}
          </h1>

          <button
            type="button"
            class="dp-top-menu-content-body__close"
            @click="deactivateMenu">
            <Icon name="dpClose" color="dpWhite01" :width="18" :height="18" />
          </button>

          <ul class="dp-top-menu-content-body__list">
            <li>
              <a
                :href="$customUrlScheme.dp(`/capsule`)"
                v-ripple="$utilities.getRippleOptions('light')"
                @click="deactivateMenu">
                <span class="dp-top-menu-content-body__icon">
                  <Icon
                    name="capsuleR"
                    :width="32"
                    :height="32"
                    color="dpBlack01" />
                </span>
                <span class="dp-top-menu-content-body__label">
                  {{ WORDS.DRIP_POD_MENU.ITEMS.CAPSULE }}
                </span>
              </a>
            </li>

            <li>
              <a
                :href="$customUrlScheme.dp(`/pro-recipe`)"
                v-ripple="$utilities.getRippleOptions('light')"
                @click="deactivateMenu">
                <span class="dp-top-menu-content-body__icon">
                  <Icon
                    name="recipe"
                    :width="32"
                    :height="32"
                    color="dpBlack01" />
                </span>
                <span class="dp-top-menu-content-body__label">
                  {{ WORDS.DRIP_POD_MENU.ITEMS.RECIPE }}
                </span>
              </a>
            </li>

            <li>
              <a
                :href="$customUrlScheme.dp(`/brew/history`)"
                v-ripple="$utilities.getRippleOptions('light')"
                @click="deactivateMenu">
                <span
                  class="dp-top-menu-content-body__icon dp-top-menu-content-body__icon--sm">
                  <Icon
                    name="dpHistoryB"
                    :width="16"
                    :height="16"
                    color="dpBlack01" />
                </span>
                <span class="dp-top-menu-content-body__label">
                  {{ WORDS.DRIP_POD_MENU.ITEMS.HISTORY }}
                </span>
              </a>
            </li>

            <li>
              <a
                :href="$customUrlScheme.dp(`/brew/my-recipe`)"
                v-ripple="$utilities.getRippleOptions('light')"
                @click="deactivateMenu">
                <span
                  class="dp-top-menu-content-body__icon dp-top-menu-content-body__icon--sm">
                  <Icon
                    name="dpBookmarkOffB"
                    :width="16"
                    :height="16"
                    color="dpBlack01" />
                </span>
                <span class="dp-top-menu-content-body__label">
                  {{ WORDS.DRIP_POD_MENU.ITEMS.MYRECIPE }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  setup: (props, context) => {
    const storeX = ref(Number);
    const storeY = ref(Number);
    const query = computed(() => context.root.$route.query);
    const isActiveMenu = computed(
      () => Object.prototype.hasOwnProperty.call(query.value, 'menu') || false
    );

    const onClickDripMenu = async () => {
      if (!isActiveMenu.value) activateMenu();
    };

    const activateMenu = () => {
      context.root.$router.push({
        query: { menu: null }
      });
      deactivateScroll();
    };

    const deactivateMenu = async () => {
      context.root.$router.push({
        query: null
      });
      await activateScroll();
      window.scrollTo(storeX.value, storeY.value);
    };

    const activateScroll = () => {
      window.onscroll = () => {};
    };

    const deactivateScroll = () => {
      const x = window.scrollX;
      const y = window.scrollY;
      storeX.value = x;
      storeY.value = y;
      window.onscroll = () => {
        window.scrollTo(x, y);
      };
    };

    if (isActiveMenu.value) {
      deactivateScroll();
    }

    return {
      WORDS,
      onClickDripMenu,
      activateMenu,
      deactivateMenu,
      isActiveMenu
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-top-menu {
  z-index: 100;
  position: relative;
}

.dp-top-menu-button {
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 72px;
  height: 72px;
  background-color: variables.$dpPrimary;
  border-radius: 10em;
  line-height: 1;
  box-shadow: 0 2px 8px 0 #5c472529;

  &__label {
    @include mixin.dpEnTitle;

    display: grid;
    place-items: center;
    margin-top: 1px;
    text-align: center;
    color: variables.$dpWhite01;
    font-weight: normal;
    white-space: pre-wrap;
  }
}

.dp-top-menu-content {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 100%;
  place-content: end center;
  color: variables.$dpBlack01;
  width: 100%;
  height: 100%;
}

.dp-top-menu-content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: variables.$dpWhite01, $alpha: 80%);
  width: 100%;
  height: 100%;
}

.dp-top-menu-content-body {
  position: relative;
  padding: 14px 16px 24px;
  background-color: variables.$dpBlack02;

  &__title {
    @include mixin.dpEnTitle;

    margin-bottom: 24px;
    color: variables.$dpWhite01;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    place-items: center;
    margin-right: -1px;
    margin-left: auto;
    padding: 16px;
    line-height: 1;
  }

  &__list {
    display: grid;
    grid: auto-flow / 1fr 1fr;
    gap: 16px;
    margin-bottom: 0;
    font-size: 12px;

    > li {
      a {
        display: grid;
        padding: 16px;
        place-content: center center;
        place-items: center;
        grid: auto-flow / 1fr;
        background-color: variables.$dpGreyF1;
        overflow: hidden;
        color: variables.$dpBlack01;
        font-weight: bold;
        text-decoration: none;
      }

      &:nth-child(n + 3) {
        a {
          grid-template-columns: 16px auto;
          gap: 4px;
          background-color: variables.$dpGreyF1;
        }
      }
    }
  }

  &__icon {
    display: grid;
    place-items: center;
    margin-bottom: 8px;
    width: 48px;
    height: 48px;

    &--sm {
      margin-bottom: 0;
      background-color: transparent;
      width: auto;
      height: auto;
    }
  }

  &__label {
    padding-top: 2px;
    font-size: 12px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
