var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dp-top-menu" },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple",
              value: _vm.$utilities.getRippleOptions("dark"),
              expression: "$utilities.getRippleOptions('dark')",
            },
          ],
          staticClass: "dp-top-menu-button",
          attrs: { type: "button" },
          on: { click: _vm.onClickDripMenu },
        },
        [
          _c(
            "span",
            { staticClass: "dp-top-menu-button__label" },
            [_c("Icon", { attrs: { name: "dpMenu", width: 38, height: 34 } })],
            1
          ),
        ]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isActiveMenu
          ? _c("div", { staticClass: "dp-top-menu-content" }, [
              _c("div", {
                staticClass: "dp-top-menu-content-overlay",
                on: { click: _vm.deactivateMenu },
              }),
              _c("div", { staticClass: "dp-top-menu-content-body" }, [
                _c("h1", { staticClass: "dp-top-menu-content-body__title" }, [
                  _vm._v(" " + _vm._s(_vm.WORDS.DRIP_POD_MENU.TITLE) + " "),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "dp-top-menu-content-body__close",
                    attrs: { type: "button" },
                    on: { click: _vm.deactivateMenu },
                  },
                  [
                    _c("Icon", {
                      attrs: {
                        name: "dpClose",
                        color: "dpWhite01",
                        width: 18,
                        height: 18,
                      },
                    }),
                  ],
                  1
                ),
                _c("ul", { staticClass: "dp-top-menu-content-body__list" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple",
                            value: _vm.$utilities.getRippleOptions("light"),
                            expression: "$utilities.getRippleOptions('light')",
                          },
                        ],
                        attrs: { href: _vm.$customUrlScheme.dp("/capsule") },
                        on: { click: _vm.deactivateMenu },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "dp-top-menu-content-body__icon" },
                          [
                            _c("Icon", {
                              attrs: {
                                name: "capsuleR",
                                width: 32,
                                height: 32,
                                color: "dpBlack01",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "dp-top-menu-content-body__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.WORDS.DRIP_POD_MENU.ITEMS.CAPSULE) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple",
                            value: _vm.$utilities.getRippleOptions("light"),
                            expression: "$utilities.getRippleOptions('light')",
                          },
                        ],
                        attrs: { href: _vm.$customUrlScheme.dp("/pro-recipe") },
                        on: { click: _vm.deactivateMenu },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "dp-top-menu-content-body__icon" },
                          [
                            _c("Icon", {
                              attrs: {
                                name: "recipe",
                                width: 32,
                                height: 32,
                                color: "dpBlack01",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "dp-top-menu-content-body__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.WORDS.DRIP_POD_MENU.ITEMS.RECIPE) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple",
                            value: _vm.$utilities.getRippleOptions("light"),
                            expression: "$utilities.getRippleOptions('light')",
                          },
                        ],
                        attrs: {
                          href: _vm.$customUrlScheme.dp("/brew/history"),
                        },
                        on: { click: _vm.deactivateMenu },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "dp-top-menu-content-body__icon dp-top-menu-content-body__icon--sm",
                          },
                          [
                            _c("Icon", {
                              attrs: {
                                name: "dpHistoryB",
                                width: 16,
                                height: 16,
                                color: "dpBlack01",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "dp-top-menu-content-body__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.WORDS.DRIP_POD_MENU.ITEMS.HISTORY) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple",
                            value: _vm.$utilities.getRippleOptions("light"),
                            expression: "$utilities.getRippleOptions('light')",
                          },
                        ],
                        attrs: {
                          href: _vm.$customUrlScheme.dp("/brew/my-recipe"),
                        },
                        on: { click: _vm.deactivateMenu },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "dp-top-menu-content-body__icon dp-top-menu-content-body__icon--sm",
                          },
                          [
                            _c("Icon", {
                              attrs: {
                                name: "dpBookmarkOffB",
                                width: 16,
                                height: 16,
                                color: "dpBlack01",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "dp-top-menu-content-body__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.WORDS.DRIP_POD_MENU.ITEMS.MYRECIPE) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }